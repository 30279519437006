exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-olympian-js": () => import("./../../../src/pages/olympian.js" /* webpackChunkName: "component---src-pages-olympian-js" */),
  "component---src-pages-sponsors-js": () => import("./../../../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-pages-timeline-js": () => import("./../../../src/pages/timeline.js" /* webpackChunkName: "component---src-pages-timeline-js" */)
}

